import * as Yup from "yup";

function aggregationFormValidation() {
  return Yup.object().shape({
    pension_provider: Yup.string()
      .required("required")
      .nullable(),
    report_date: Yup.string()
      .required("required")
      .nullable(),
    policy_number: Yup.string()
      .required("required")
      .nullable(),
    dob: Yup.string()
      .required("required")
      .nullable(),
    pension_name: Yup.string()
      .required("required")
      .nullable(),
    policy_commenced_date: Yup.string()
      .required("required")
      .nullable(),
    retirement_date: Yup.string()
      .required("required")
      .nullable(),
    registered_scheme_name: Yup.string()
      .required("required")
      .nullable(),
    fund_value: Yup.string()
      .required("required")
      .nullable(),
    transfer_value: Yup.string()
      .required("required")
      .nullable(),
    transfer_date: Yup.string()
      .required("required")
      .nullable(),
    full_transfer: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    fund_investments: Yup.string()
      .required("required")
      .nullable(),
    profits: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    withdrawels: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    crystallised_pension: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    contributions: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    employer_contributions: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    regular_contributions_restarted: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    policy_transfer_accepted: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    annual_management_fee: Yup.string()
      .required("required")
      .nullable(),
    fund_management_fee: Yup.string()
      .required("required")
      .nullable(),
    exit_fees: Yup.string()
      .required("required")
      .nullable(),
    fund_switching_fees: Yup.string()
      .required("required")
      .nullable(),
    advisor_fees: Yup.string()
      .required("required")
      .nullable(),
    leave_pot_untouched: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    guaranteed_income: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    adjustable_income: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    take_cash_in_chunks: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    take_whole_pot: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    mix_options: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    waiver_of_premium: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    loyalty_bonus: Yup.string()
      .required("required")
      .nullable(),
    protect_retirement_age: Yup.string()
      .required("required")
      .max(3)
      .nullable(),
    tax_free_cash_gt_25: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    tax_free_cash: Yup.string()
      .required("required")
      .nullable(),
    ltc_apply: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    lta_protection_type: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    access_policy_details: Yup.string()
      .required("required")
      .nullable(),
    pension_sharing: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    pension_earmarking: Yup.string()
      .required("required")
      .nullable()
      .nullable(),
    power_of_atternay: Yup.string()
      .required("required")
      .nullable()
  });
}

export { aggregationFormValidation };
