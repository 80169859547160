<template>
  <div class="container">
    <!--    <BackButton />-->
    <h4 class="mb-5">Pension Report | Form</h4>
    <CopyText title="UUID" :value="pensionReportUuid" class="mb-5" />
    <div class="card">
      <div class="card-body">
        <Form
          class="w-100 text-left"
          @submit="handleReview"
          :validation-schema="schema"
        >
          <div class="row">
            <div class="col-6">
              <SelectInput
                name="adjustable_income"
                label="Adjustable income"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.adjustable_income'
                  )
                "
              />
              <AmountInput
                name="advisor_fees"
                label="Advisor fees"
                :initial-value="
                  evalutateProperty('getStoredAggregationReport?.advisor_fees')
                "
              />
              <TextInput
                type="text"
                placeholder=""
                name="annual_management_fee"
                label="Annual management fee"
                :initial-value="
                  evalutateProperty(
                    'getStoredAggregationReport?.annual_management_fee'
                  )
                "
              />
              <SelectInput
                name="contributions_currently_being_made"
                label="Contribiution currently being made"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.contributions_currently_being_made'
                  )
                "
              />
              <DatePicker
                name="report_date"
                label="Report date"
                placeholder=""
                :value="reportDateComputed"
              />
              <SelectInput
                name="crystallised_pension"
                label="Crystallised pension"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.crystallised_pension'
                  )
                "
              />
              <SelectInput
                name="death_benefits"
                label="Death benefits "
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.death_benefits'
                  )
                "
              />
              <TextInput
                name="death_benefits_text"
                type="text"
                label="Death benefits text"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.death_benefits_text'
                  )
                "
              />
              <DatePicker
                name="dob"
                label="Date of birth"
                placeholder=""
                :value="dobComputed"
              />
              <SelectInput
                name="employer_contribution"
                label="Employer contributions"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.employer_contribution'
                  )
                "
              />
              <SelectInput
                name="fees_charges_penalties"
                label="Exit fees / Charges / Penalties"
                class="confirmationYes"
                :options="selectConfirmationNA"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.fees_charges_penalties'
                  )
                "
              />
              <TextInput
                name="fees_charges_penalties_text"
                type="text"
                label="Exit Fees / Charges / Penalties Text"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.fees_charges_penalties_text'
                  )
                "
              />
              <AmountInput
                name="fund_management_fee"
                label="Fund manageent fee"
                :initial-value="
                  evalutateProperty(
                    'getStoredAggregationReport?.fund_management_fee'
                  )
                "
              />
              <TextInput
                type="text"
                placeholder=""
                 name="fund_switching_fees"
                label="Fund switching fees"
                :initial-value="
                  evalutateProperty(
                    'getStoredAggregationReport?.fund_switching_fees'
                  )
                "
              />
              <AmountInput
                name="fund_value"
                label="Fund value"
                :initial-value="
                  evalutateProperty('getStoredAggregationReport?.fund_value')
                "
              />
              <DatePicker
                name="fund_value_date"
                label="Fund value date"
                placeholder=""
                :value="fundValueDataComputed"
              />
              <SelectInput
                name="guaranteed_income"
                label="Guaranteed income"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.guaranteed_income'
                  )
                "
              />
              <SelectInput
                name="life_assurance"
                label="Life assurance"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.life_assurance'
                  )
                "
              />
              <TextInput
                name="life_assurance_text"
                type="text"
                label="Life assurance text"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.life_assurance_text'
                  )
                "
              />
              <SelectInput
                name="loyalty_bonus"
                label="Loyalty bonus"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty('getStoredAggregationReport?.loyalty_bonus')
                "
              />
              <TextInput
                name="loyalty_bonus_text"
                type="text"
                label="Loyalty bonus text"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.loyalty_bonus_text'
                  )
                "
              />
              <SelectInput
                name="mix_your_options"
                label="Mix your options "
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.mix_your_options'
                  )
                "
              />
              <TextInput
                name="name_of_employer"
                type="text"
                label="In relation to employment"
                placeholder=""
                :value="employerComputed"
              />
              <SelectInput
                name="pension_earmarking"
                label="Pension earmarking"
                class="confirmationYes"
                :options="selectConfirmationNA"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.pension_earmarking'
                  )
                "
              />
              <TextInput
                name="pension_name"
                type="text"
                label="Pension name"
                placeholder=""
                :value="
                  evalutateProperty('getStoredAggregationReport?.pension_name')
                "
              />

              <TextInput
                name="pension_provider"
                type="text"
                label="Pension provider"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.pension_provider'
                  )
                "
              />
            </div>
            <div class="col-6">
              <TextInput
                name="pension_scheme_tax_reference"
                type="text"
                label="Pension scheme tax reference"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.pension_scheme_tax_reference'
                  )
                "
              />
              <TextInput
                name="pension_type"
                type="text"
                label="Policy type"
                placeholder=""
                :value="
                  evalutateProperty('getStoredAggregationReport?.pension_type')
                "
              />
              <SelectInput
                name="policy_accept_transfers_in"
                label="Can this policy accept transfers in?"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.policy_accept_transfers_in'
                  )
                "
              />
              <DatePicker
                name="policy_commence_date"
                label="Date policy commenced"
                placeholder=""
                :value="policyCommenceDateComputed"
              />
              <TextInput
                name="policy_number"
                type="text"
                label="Policy number"
                placeholder=""
                :value="
                  evalutateProperty('getStoredAggregationReport?.policy_number')
                "
              />
              <SelectInput
                name="policy_permits_full_transfer_out"
                label="Policy permits full transfers out"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.policy_permits_full_transfer_out'
                  )
                "
              />
              <SelectInput
                name="power_of_attorney"
                label="Power of attorney "
                class="confirmationYes"
                :options="selectConfirmationNA"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.power_of_attorney'
                  )
                "
              />
              <TextInput
                name="power_of_attorney_text"
                type="text"
                label="Power of attorney text"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.power_of_attorney_text'
                  )
                "
              />
              <SelectInput
                name="protect_retirement_age"
                label="Protect retirement age"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.protect_retirement_age'
                  )
                "
              />
              <TextInput
                name="protect_retirement_age_text"
                type="text"
                label="Protect retirement age value"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.protect_retirement_age_text'
                  )
                "
              />
              <TextInput
                name="registered_scheme_name"
                type="text"
                label="Registered scheme name"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.registered_scheme_name'
                  )
                "
              />
              <SelectInput
                name="regular_contributions"
                label="Regular contributions"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.regular_contributions'
                  )
                "
              />
              <DatePicker
                name="retirement_date"
                label="Selected retirement date"
                placeholder=""
                :value="retirementDateComputed"
              />
              <SelectInput
                name="safeguard_benefits"
                label="Safeguard benefits"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.safeguard_benefits'
                  )
                "
              />
              <TextInput
                name="safeguard_benefits_text"
                type="text"
                label="Safeguard benefits details"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.safeguard_benefits_text'
                  )
                "
              />
              <SelectInput
                name="singular_contributions"
                label="Singular contributions"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.singular_contributions'
                  )
                "
              />
              <SelectInput
                name="take_cash_in_chunks"
                label="Take cash in chunks"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.take_cash_in_chunks'
                  )
                "
              />
              <SelectInput
                name="take_your_whole_pot"
                label="Take your whole pot"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.take_your_whole_pot'
                  )
                "
              />
              <SelectInput
                name="tax_free_cash_greater_than_25_perc"
                label="Tax free cash greater than 25%"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.tax_free_cash_greater_than_25_perc'
                  )
                "
              />
              <AmountInput
                name="transfer_value"
                label="Transfer value"
                :initial-value="
                  evalutateProperty(
                    'getStoredAggregationReport?.transfer_value'
                  )
                "
              />
              <DatePicker
                name="transfer_value_date"
                label="Transfer value date"
                placeholder=""
                :value="transferValueDateComputed"
              />
              <SelectInput
                name="waiver_of_premium"
                label="Waiver of premium"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.waiver_of_premium'
                  )
                "
              />
              <TextInput
                name="waiver_of_premium_text"
                type="text"
                label="Waiver of premium value"
                placeholder=""
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.waiver_of_premium_text'
                  )
                "
              />
              <SelectInput
                name="with_profits_fund"
                label="With profits "
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.with_profits_fund'
                  )
                "
              />
              <SelectInput
                name="withdrawels_from_this_pension"
                label="Withdrawels from this pension"
                class="confirmationYes"
                :options="selectConfirmationYes"
                :value="
                  evalutateProperty(
                    'getStoredAggregationReport?.withdrawels_from_this_pension'
                  )
                "
              />
            </div>
            <div class="col-12">
              <button
                class="fabric-btn fabric-btn-submit"
                @click="scrollToError"
              >
                Review Report
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { aggregationFormValidation } from "@/setup/formValidation";
import DatePicker from "@/components/form/DatePicker";
import CopyText from "@/components/misc/CopyText";

export default {
  name: "Aggregation Form",
  components: {
    DatePicker,
    CopyText
  },
  setup() {
    const schema = aggregationFormValidation();
    return {
      schema
    };
  },
  data: () => ({
    report_date: null,
    policy_commenced_date: null,
    retirement_date: null,
    selectConfirmationYes: [
      {
        id: "yes",
        name: "Yes"
      },
      {
        id: "no",
        name: "No"
      }
    ],
    selectConfirmationNA: [
      {
        id: "yes",
        name: "Yes"
      },
      {
        id: "na",
        name: "NA"
      }
    ],
    aggregationReport: null
  }),
  computed: {
    ...mapGetters([
      "getApplicationUuid",
      "getApplication",
      "getStoredAggregationReport",
      "getTracedPension"
    ]),
    employerComputed() {
      return this.getApplication.employment[0].name_of_employer;
    },
    dobComputed() {
      return this.parseDate(this.evalutateProperty("getApplication?.dob"));
    },
    fundValueDataComputed() {
      return this.parseDate(
        this.evalutateProperty("getStoredAggregationReport?.fund_value_date")
      );
    },
    reportDateComputed() {
      return this.parseDate(
        this.evalutateProperty("getStoredAggregationReport?.report_date")
      );
    },
    policyCommenceDateComputed() {
      return this.parseDate(
        this.evalutateProperty(
          "getStoredAggregationReport?.policy_commence_date"
        )
      );
    },
    retirementDateComputed() {
      return this.parseDate(
        this.evalutateProperty("getStoredAggregationReport?.retirement_date")
      );
    },
    transferValueDateComputed() {
      return this.parseDate(
        this.evalutateProperty(
          "getStoredAggregationReport?.transfer_value_date"
        )
      );
    },
    pensionReportUuid() {
      return this.getStoredAggregationReport?.uuid;
    }
  },
  methods: {
    ...mapMutations([
      "submitAggregationDetails",
      "setAggregationReport",
      "setLoading"
    ]),
    ...mapActions(["getAggregationReport", "updateAggregationReport"]),
    async handleReview(params) {
      this.setLoading({
        bool: true
      });
      let tempParams = this.formatPensionReportRequest(params) || {};
      let tpuuid = this.pensionReportUuid;
      let response = await this.updateAggregationReport({
        tpuuid,
        tempParams
      }).catch(err => console.error(err));

      this.setLoading({
        bool: false
      });

      if (!response) return;

      this.$router.push({
        name: "Pension Review",
        params: {
          tpuuid: this.$route.params.tpuuid
        }
      });
    }
  },
  async beforeMount() {
    this.setLoading({
      bool: true
    });

    await this.getAggregationReport(this.$route.params.tpuuid).catch(err =>
      console.error(err.response.data.error)
    );

    this.setLoading({
      bool: false
    });
  }
};
</script>
<style lang="scss" scoped>
.confirmationYes {
  display: block;
  width: 300px;
}
</style>
